import React, { useEffect, useState } from 'react';
import Header from './Header';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { signIn } from './oidcHelper';
const settings = {
    automaticSilentRenew: false,
    monitorSession: false,
    stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
    userStore: new WebStorageStateStore({ store: window.sessionStorage })
};

function useStickyState(defaultValue, key) {
    const [value, setValue] = React.useState(() => {
        const stickyValue = window.localStorage.getItem(key);
        return stickyValue !== null
            ? JSON.parse(stickyValue)
            : defaultValue;
    });
    React.useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
}

let isFetching = false;

const getUser = async (authority, client_id, redirect_uri): Promise<string> => {
    const userManager = new UserManager({
        ...settings,
        authority,
        client_id,
        redirect_uri
    }

    );
    if (hasCodeInUrl(location) && !isFetching) {
        isFetching = true;
        console.log(userManager.getUser());
        const response = await userManager.signinCallback();
        removeSensitiveInfoFromUrl();
        console.log(`sudhish removing sensitive info`)
        isFetching = false;
        if (response) return response.access_token
    }
    return "";
};

const removeSensitiveInfoFromUrl = () => {
    console.log('authservice: cleaning url');
    const uri = window.location.toString();
    if (uri.indexOf('?') > 0) {
        const clean_uri = uri.substring(0, uri.indexOf('?'));
        window.history.replaceState({}, document.title, clean_uri);
    }
};

export const hasCodeInUrl = (location: Location): boolean => {
    const searchParams = new URLSearchParams(location.search);
    const hashParams = new URLSearchParams(location.hash.replace('#', '?'));

    return Boolean(
        searchParams.get('code') ||
        searchParams.get('id_token') ||
        searchParams.get('session_state') ||
        hashParams.get('code') ||
        hashParams.get('id_token') ||
        hashParams.get('session_state'),
    );
};

function App() {

    const [accessToken, setAccessToken] = useState();
    const [redirectURI, setRedirectURI] = useStickyState('', 'redirect-uri');
    const [authority, setAuthority] = useStickyState('', 'authority');
    const [clientId, setClientId] = useStickyState('', 'clientId');
    const [scope, setScope] = useStickyState('', 'scope');

    const handleSubmit = (e) => {
        const userManager = new UserManager({
            ...settings,
            authority,
            client_id: clientId,
            scope,
            redirect_uri: redirectURI
        });
        signIn(userManager)
        e.preventDefault();
    }

    useEffect(() => {
        getUser(authority, clientId, redirectURI).then(setAccessToken);
    }, []);
    
    return (
        <div className="App">
            <Header/>
            <div className="container">
                <div className="row">
                    <h1>Token Helper</h1>
                </div>
                { accessToken &&
                        <div className="row">
                            <div className="col-12">
                                <h2>Access Token</h2>
                                <div id="accessToken" style={{ wordWrap: "break-word" }}>{accessToken}</div>
                            </div>
                        </div>
                }
                <div className="row">
                    <div className="pds-form col-12">
                        <form className="pds-form-wrapper pds-form-enable-validations" action="#">
                            <div className="pds-form-field">
                                <div className="pds-labeledInput">
                                    <label htmlFor="authority" className="pds-label">
                                        Authority URL
                                        <span className="pds-label-required" aria-hidden="true">*</span>
                                    </label>

                                    <input id="authority"
                                           type="text"
                                           value={authority}
                                           onChange={(e) => setAuthority(e.target.value)}
                                           required
                                           aria-label=""
                                           aria-required="true"
                                    />
                                </div>
                            </div>
                            <div className="pds-form-field">
                                <div className="pds-labeledInput">
                                    <label htmlFor="client-id" className="pds-label">
                                        Client ID
                                        <span className="pds-label-required" aria-hidden="true">*</span>
                                    </label>

                                    <input id="client-id"
                                           aria-label=""
                                           type="text"
                                           required
                                           aria-required="true"
                                           value={clientId}
                                           onChange={(e) => setClientId(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="pds-form-field">
                                <div className="pds-labeledInput">
                                    <label htmlFor="redirect-uri" className="pds-label">
                                        Redirect URL
                                        <span className="pds-label-required" aria-hidden="true">*</span>
                                    </label>

                                    <input id="redirect-uri"
                                           aria-label=""
                                           type="text"
                                           required
                                           aria-required="true"
                                           value={redirectURI}
                                           onChange={(e) => setRedirectURI(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="pds-form-field">
                                <div className="pds-labeledInput">
                                    <label htmlFor="scope" className="pds-label">
                                        Scopes (space delimited)
                                        <span className="pds-label-required" aria-hidden="true">*</span>
                                    </label>

                                    <input id="scope"
                                           aria-label=""
                                           type="text"
                                           required
                                           aria-required="true"
                                           value={scope}
                                           onChange={(e) => setScope(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="pds-form-ctas">
                                <input id='submitButton' type="submit" className="pds-button pds-button-primary" value="Get Token" onClick={handleSubmit} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
