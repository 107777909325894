import React from 'react';

export default function Header() {
    return (
        <nav className="pds-navigationPrimary pds-navigationPrimary-primary">
        <div className="pds-navigationPrimary-container container">
          <div className="pds-navigationPrimary-logo row">
            <a data-gtm="navigation-primary" className="pds-navigationPrimary-logo-link pds-link-unstyled .offset-md-4" href="#" aria-label="Principal Homepage">
              <img alt="" className="pds-imgLogoCompanyNameWhiteRetina" />
            </a>
          </div>
        </div>
      </nav>
    );
}